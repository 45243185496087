<template>
  <div class="hinweis card">
    <div class="card-content">
      <span class="card-title">Fehler beim Laden der Daten</span>
      <p>Ihre Daten konnten nicht geladen werden. Bitte wenden Sie sich an <a href="mailto:sponsorenerfassung@lions-emmerich-rees.de">sponsorenerfassung@lions-emmerich-rees.de</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fehler',
};
</script>

<style scoped></style>
